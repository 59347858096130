import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Header from "../components/AboutUsPage/Header"
import GoldBar from "../components/AboutUsPage/GoldBar"
import FirstSection from "../components/AboutUsPage/FirstSection"
import SecondSection from "../components/AboutUsPage/SecondSection"
import ThirdSection from "../components/AboutUsPage/ThirdSection"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl, navigate } from "gatsby-plugin-react-intl"
import GoogleSection from "../components/AboutUsPage/GoogleSection"
import VideoSection from "../components/AboutUsPage/VideoSection"

const AboutUsPageEN = () => {
  const intl = useIntl()
  const lang = intl.locale
  const [data, setData] = useState({})
  const isBrowser = typeof window !== "undefined"

  const query = useStaticQuery(graphql`
    query AboutUsEN {
      polish: contentfulAboutUs(node_locale: { eq: "pl-PL" }) {
        client1
        client1_text {
          client1_text
        }
        client2
        client2_text {
          client2_text
        }
        client3
        client3Text {
          client3Text
        }
        client4
        client4_text {
          client4_text
        }
        client5
        client5_text {
          client5_text
        }
        field1 {
          raw
        }
        field2 {
          raw
        }
        goldBar1
        goldBar2
        happy_clients
        icon1
        icon2
        icon3
        icon4
        slider1
        see_opinions
        rating_quantity
        rating_description
        rating_name
      }

      english: contentfulAboutUs(node_locale: { eq: "en-US" }) {
        client1
        client1_text {
          client1_text
        }
        client2
        client2_text {
          client2_text
        }
        client3
        client3Text {
          client3Text
        }
        client4
        client4_text {
          client4_text
        }
        client5
        client5_text {
          client5_text
        }
        field1 {
          raw
        }
        field2 {
          raw
        }
        goldBar1
        goldBar2
        happy_clients
        icon1
        icon2
        icon3
        icon4
        slider1
        see_opinions
        rating_quantity
        rating_description
        rating_name
      }
      meta: contentfulMeta(node_locale: { eq: "pl-PL" }) {
        author
        description {
          description
        }
        keywords
        title
      }
    }
  `)

  useEffect(() => {
    if (lang === "pl") {
      navigate("/o-nas/")
    }
  }, [lang])

  useEffect(() => {
    if (isBrowser) {
      if (intl.locale === "en") {
        setData(query.english)
      }
      if (intl.locale === "pl") {
        setData(query.polish)
      }
    }
  }, [intl])

  return (
    <Layout>
      <Seo title="About Us" />
      <Header slider1={data.slider1} />
      <FirstSection field1={data.field1} field2={data.field2} />
      <VideoSection link={"https://www.youtube.com/embed/QoogZYu5rpw"} />
      <SecondSection
        icon1={data.icon1}
        icon2={data.icon2}
        icon3={data.icon3}
        icon4={data.icon4}
      />
      <GoldBar goldBar1={data.goldBar1} goldBar2={data.goldBar2} />
      <ThirdSection
        happy_clients={data.happy_clients}
        client1={data.client1}
        client2={data.client2}
        client3={data.client3}
        client4={data.client4}
        client5={data.client5}
        client1_text={data.client1_text && data.client1_text.client1_text}
        client2_text={data.client2_text && data.client2_text.client2_text}
        client3_text={data.client3Text && data.client3Text.client3Text}
        client4_text={data.client4_text && data.client4_text.client4_text}
        client5_text={data.client5_text && data.client5_text.client5_text}
      />
      <GoogleSection
        see_opinions={data.see_opinions}
        rating_quantity={data.rating_quantity}
        rating_description={data.rating_description}
        rating_name={data.rating_name}
      />
    </Layout>
  )
}

export default AboutUsPageEN
